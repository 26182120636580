<template>
  <div class="component">
    <div class="top_line py-5">
      <h1 class="tilte text-2xl fw-b ">
        Oops! You have reached your {{ items }} quota.
      </h1>
    </div>
    <div class="body">
      <div class="bg-yellow-lighter container content py-6 m-auto"
        :class="{'w-2/3': !fullWidth}"
      >
        <h2>
          You have added {{count}}/{{count}} {{itemsProps}}. Please
          <router-link class="text-green" :to="{name: 'ChangePlan'}">upgrade your plan</router-link>
          or purchase
          <router-link class="text-green" :to="{name: 'Addons'}">addon</router-link>
        </h2>
      </div>
      <div class="actions py-5" v-if="!fullWidth">
        <button class="green-white" @click="goBack()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutOfLimit",
  props: {
    items: {
      type: String,
      default: ''
    },
    itemsProps: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped>

</style>