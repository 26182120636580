<template>
  <div class="w-full">
    <div class="flex justify-between mb-4">
      <div class="w-1/3 text-left py-4 section_name">
        Account details
      </div>

      <div class="w-2/3 flex justify-between">
        <div class="form_row w-full md:w-1/2 p-4">
          <label for="company_name" class="block text-gray-700 mb-2 text-left input_label">
            Company name <span>*</span>
          </label>
          <input
              @input="updateDisableButton"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="company_name"
              @click="skipErrors('company_name')"
              v-model="form.company_name"
              type="text"
              placeholder="Company name">
          <p v-if="errors.company_name" class="text-red-500 text-xs italic text-left py-2">
            This field is required
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-between mb-4">
      <div class="w-1/3 text-left py-4 section_name">
        User details
      </div>

      <div class="w-2/3">
        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="name" class="block text-gray-700 mb-2 text-left input_label">
              Contact name <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                @click="skipErrors('contact_name')"
                v-model="form.contact_name"
                type="text"
                placeholder="Name">
            <p v-if="errors.contact_name" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="phone" class="block text-gray-700 mb-2 text-left input_label">
              Phone <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phone"
                @click="skipErrors('phone')"
                v-model="form.phone"
                type="text"
                placeholder="Phone">
            <p v-if="errors.phone" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>
        </div>

        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="email" class="block text-gray-700 mb-2 text-left input_label">
              Email <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                @click="skipErrors(errors.email ? 'email' : (errors.email_form ? 'email_form' : null))"
                v-model="form.email"
                type="text"
                placeholder="Email">
            <p v-if="errors.email" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.email_form" class="text-red-500 text-xs italic text-left py-2">
              Please check this field
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="username" class="block text-gray-700 mb-2 text-left input_label">
              Username <span>*</span>
            </label>
            <input
                disabled
                @input="updateDisableButton"
                class="disabled shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                @click="skipErrors('username')"
                v-model="form.username"
                type="text"
                placeholder="Username">
            <p v-if="errors.username" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between mb-4">
      <div class="w-1/3 text-left py-4 section_name">
        Address details
      </div>

      <div class="w-2/3">
        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="address_line1" class="block text-gray-700 mb-2 text-left input_label">
              Address 1 <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="address_line1"
                @click="skipErrors('address_1')"
                v-model="form.address_1"
                type="text"
                placeholder="Address 1">
            <p v-if="errors.address_1" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="address_line2" class="block text-gray-700 mb-2 text-left input_label">
              Address 2
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="address_line2"
                @click="skipErrors('address_2')"
                v-model="form.address_2"
                type="text"
                placeholder="Address 2">
          </div>
        </div>

        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="town_city" class="block text-gray-700 mb-2 text-left input_label">
              Town/city <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="town_city"
                @click="skipErrors('city')"
                v-model="form.city"
                type="text"
                placeholder="Town/city">
            <p v-if="errors.city" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="postcode" class="block text-gray-700 mb-2 text-left input_label">
              Postcode <span>*</span>
            </label>
            <input
                @input="updateDisableButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="postcode"
                @click="skipErrors('postcode')"
                v-model="form.postcode"
                type="text"
                placeholder="Postcode">
            <p v-if="errors.postcode" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>
        </div>

        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <div ref="search_countries" class="w-full text-left relative">
              <LLRADropdownDop
                  :label="'Country'"
                  :defaultList="countriesList"
                  labelStyle="block text-gray-700 mb-2 text-left input_label"
                  inputStyle="block shadow appearance-none border rounded bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
              ></LLRADropdownDop>
            </div>
            <p v-if="errors.country" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between mb-4">
      <div class="w-1/3 text-left py-4 section_name">
        Fleet size
      </div>

      <div class="w-2/3">
        <div class="form_row w-full md:w-1/2 p-4">
          <label for="grid-state-fleet" class="block text-gray-700 mb-2 text-left input_label">
            How many vehicles do you manage in you business <span>*</span>
          </label>
          <div class="relative">
            <select
                v-on:change="updateDisableButton"
                v-model="form.fleet_size"
                class="block shadow appearance-none border rounded w-full bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
                id="grid-state-fleet">
              <option disabled value="">Select...</option>
              <option
                  v-for="(fleet, index) in fleet_sizes"
                  :key="'fleet-' + index"
                  :value="fleet.id">{{fleet.name}}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
          <p v-if="errors.fleet_size" class="text-red-500 text-xs italic text-left py-2">
            This field is required
          </p>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-end items-center py-6 px-2">
      <button
          :disabled="update_disable"
          :class="update_disable ? 'bg-gray-400 border-gray-500' : 'bg-green-500 hover:bg-gray-200 hover:text-green-500 border-green-500'"
          @click="updateUser"
          class="this_button py-3 px-1 text-white border">
        Update
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import LLRADropdownDop from "../components/LLRADropdownDop";
import {infoMessage} from "../services/messages";

export default {
  name: "Profile",
  data() {
    return {
      user: null,
      old_user_data: null,
      update_disable: true,
      countriesList: [],
      filteredList: [],
      fleet_sizes:[
        {
          name: '1-5',
          id: '1-5'
        },
        {
          name: '5-10',
          id: '5-10'
        },
        {
          name: '10-15',
          id: '10-15'
        },
        {
          name: '15-20',
          id: '15-20'
        },
        {
          name: '20-50',
          id: '20-50'
        },
        {
          name: '50+',
          id: '50+'
        }
      ],
      form: {
        company_name: null,
        contact_name: null,
        phone: null,
        email: null,
        username: null,
        address_1: null,
        address_2: null,
        city: null,
        postcode: null,
        country: '',
        fleet_size: '',
      },
      errors: {
        address_1: false,
        city: false,
        company_name: false,
        contact_name: false,
        country: false,
        email: false,
        email_form: false,
        fleet_size: false,
        phone: false,
        postcode: false,
        username: false,
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
      'loginUser'
    ]),
    async getUser() {
      this.user = await JSON.parse(localStorage.getItem('user')) || null;
      if (this.user) {
        await this.fillForm();
      }
    },
    async fillForm() {
      this.form = this.user;
      this.old_user_data = this.user;
    },
    skipErrors(val = null) {
      if (val) {
        this.errors[val] = false;
      } else {
        for (let key in this.errors) {
          this.errors[key] = false;
        }
      }
    },
    updateDisableButton() {
      this.update_disable = false;
    },
    validationEmail(email) {
      const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(email).toLowerCase());
    },
    async validationForm() {
      let empty_errors = true;
      for (let key in this.errors) {
        if (!this.form[key] && key != 'email_form') {
          this.errors[key] = true;
          empty_errors = false;
        }
        if (key == 'country' && this.form[key] === '') {
          this.errors[key] = true;
          empty_errors = false;
        }
        if (key == 'fleet_size' && this.form[key] === '') {
          this.errors[key] = true;
          empty_errors = false;
        }
        if (key == 'email_form' && !this.errors.email && this.form.email) {
          if (!this.validationEmail(this.form.email)) {
            this.errors.email_form = true;
            empty_errors = false;
          }
        }
      }

      return empty_errors;
    },
    async updateUserCountry() {
      if (this.user.country && this.countriesList.length) {
        this.countriesList.forEach(item => {
          if (item.id === this.user.country) {
            this.setSearchSelectedOption(item);
          }
        });
      }
    },
    async updateUser() {
      this.skipErrors();

      let valid = await this.validationForm();

      if (valid) {
        const res = await this.$http.post(`${this.$http.apiUrl()}/users/${this.user.id}/update` , this.form).catch(err => {
          const errors = err.response?.data?.message;
          if (typeof errors === 'string') {
            return infoMessage(
                errors || 'Oops. Something went wrong...',
                this.getTopBanner ? 'error open_top_banner' :'error'
            );
          }
          if (typeof errors === 'object') {
            const errorsToString = errors.join(', ');
            return infoMessage(
                errorsToString || 'Oops. Something went wrong...',
                this.getTopBanner ? 'error open_top_banner' :'error'
            );

          }

        });

        let this_user = res?.data?.user || null;

        if(this_user){
          localStorage.setItem('user', JSON.stringify(this_user));
          infoMessage(
              `User ${this_user.username} was updated!`,
              this.getTopBanner ? 'success open_top_banner' : 'success'
          );
        }
      }

      this.update_disable = true;
    },
    onSearchHandler(ev) {
      const val = ev?.target?.value || '';
      const search = val.trim();
      const result = this.countriesList.filter(country => {
        if (country?.name?.indexOf(search) !== -1) {
          return country;
        }
      })
      this.filteredList = result;
    },
    async getCountries() {
      try {
        const url = 'https://restcountries.com/v3.1/all?fields=name,cca2';
        const result = await this.$http.get(url);
        let countries = []
        result.data.forEach((country) => {
          countries.push({
            name: country.name.common,
            id: country.cca2
          })
        })
        const sorted = countries.slice().sort(function(a, b){
          return (a.name > b.name) ? 1 : -1;
        });
        this.countriesList = sorted;
        this.filteredList = sorted;
      } catch (e) {
        console.log(e);
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.getCountries();
    await this.updateUserCountry();
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption',
      'getTopBanner'
    ]),
  },
  components: {
    LLRADropdownDop
  },
  watch: {
    getSearchSelectedOption (val) {
      if (val) {
        this.form.country = val.id;
        if (this.old_user_data.country != this.form.country) {
          this.update_disable = false;
        }
        this.skipErrors('country');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.section_name {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 700;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
.search-dropdown {
  position: absolute;
  width: 85%;
  left: 5px;
  top: 6px
}
.disabled {
  background-color: #f8f9fa;
  color: #6c757d;
  cursor: not-allowed;
}
</style>